import { useStore } from "vuex"
import { createRouter, createWebHistory } from "vue-router"

const routes = [
    {
        path: "/",
        name: "HomeView",
        component: () => import("../views/home/HomeView.vue"),
    },
    {
        path: "/manage/product/category",
        name: "ProductCategoryManageView",
        component: () => import("../views/manage/ManageProductCategoryView.vue"),
    },
    {
        path: "/lotto/promo1/",
        name: "LottoPromo1View",
        component: () => import("../views/lotto/LottoPromo1View.vue"),
        meta: {
            requiresFirstVisit: true,
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFoundView",
        component: () => import("../views/404/NotFoundView.vue"),
    },
]

const router = createRouter({
    base: "/",
    mode: "history", // 启用历史模式
    history: createWebHistory(),
    routes,
})

// MARK - 路由守卫
let lastPosition = 0
// ************************************************************************
router.beforeEach((to, from, next) => {
    const store = useStore()
    // 解决刷新后页面不会回到顶部的问题
    document.body.scrollTop = 0 // firefox
    document.documentElement.scrollTop = 0 // safari
    window.pageYOffset = 0 // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁

    // if (to.matched.some(record => record.meta.requiresFirstVisit)) {
    //     if (store.getters.getIsFirstVisit) {
    //         alert("llllllllllllllllll")
    //         store.commit('maskVisited')
    //         next()
    //     } else {
    //         next({ name: 'NotFoundView' })
    //     }
    // } else {
    //     next()
    // }
    // 获取用户登录状态
    // const token = store.getters.getToken
    // if (to.meta.requiresAuth && token.length < 1) {
    // next({ name: 'LoginView' })
    // return
    // } else {
    // 获取用户信息
    // if (token.length > 0) {
    // store.dispatch('FetchUserInfo')
    // }
    // next()
    // }
    // 阻止后退操作
    if (from.path !== "/" && to.path === from.path) {
        next(false)
    } else {
        next()
    }
    next()
})

router.afterEach((to, from) => {
    const store = useStore()
    const curPosition = window.history.state.position
    if (curPosition > lastPosition) {
        store.commit("addPageDepth")
    }
    if (curPosition < lastPosition) {
        store.commit("page/reducePageDepth")
    }
    // colog.debug("history position => " + window.history.state.position)
})

export default router
