import { createApp } from 'vue'
import App from './App.vue'
import "vant/lib/index.css";
import store from './store';
import router from './router';
import i18n from './i18n';
import {
    Image as VanImage, Button, Search,
    Tabbar, TabbarItem, NavBar, Swipe, SwipeItem,
    Col, Row, Grid, GridItem, Step, Steps,
    Popup, Toast, Dialog, PasswordInput, NumberKeyboard,
    Field, Cell, CellGroup, Form, Space, DatePicker, Picker, Signature,
    Sidebar, SidebarItem, Tag, List, Cascader, NoticeBar,
    Empty, Divider, Notify, Loading, Overlay, PullRefresh
} from "vant";
import NutBig from "@nutui/nutui-bingo";
import "@nutui/nutui-bingo/dist/style.css";


// create a new app instance
const app = createApp(App)

// 引用vant components
const vantComponents = [
    VanImage, Button, Search,
    Tabbar, TabbarItem, NavBar, Swipe, SwipeItem,
    Col, Row, Grid, GridItem, Step, Steps,
    Popup, Toast, Dialog, PasswordInput, NumberKeyboard,
    Field, Cell, CellGroup, Form, Space, DatePicker, Picker, Signature,
    Sidebar, SidebarItem, Tag, List, Cascader, NoticeBar,
    Empty, Divider, Notify, Loading, Overlay, PullRefresh
]
vantComponents.forEach(component => {
    app.use(component)
})

// 引用NutBig
app.use(NutBig)

// 引用i18n
app.use(i18n)
// 引用vuex
app.use(store)
// 引用路由
app.use(router)
// mount the app
app.mount('#app')
