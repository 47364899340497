
import { getCookie, setCookie } from '@/store/cookies/cookieMgr'

const page = {
    state: {
        pageTitle: 'default',   // the title of current page
        pageDepth: 0,           // the depth of current page
        disableBack: false,     // disable back button
        isFirstVisit: null,    // whether it is the first time to visit the page
        hideNavbar: false,      // hide navbar
    },

    getters: {
        /**
         * Retrieves the page title from the given state object.
         *
         * @param {object} state - the state object
         * @return {string} the page title
         */
        getPageTitle: (state) => {
            return state.pageTitle
        },
        /**
         * Get the page depth from the state.
         *
         * @param {Object} state - The state object
         * @return {number} The page depth
         */
        getPageDepth: (state) => {
            return state.pageDepth
        },
        /**
         * Check if the page depth is zero.
         *
         * @param {object} state - the state object
         * @return {boolean} true if the page depth is zero, false otherwise
         */
        isPageDepthZero: (state) => {
            return state.pageDepth === 0
        },
        /**
         * Retrieves the value of disableBack from the given state.
         *
         * @param {Object} state - the state object
         * @return {boolean} the value of disableBack
         */
        getDisableBack: (state) => {
            return state.disableBack
        },
        getIsFirstVisit: (state) => {
            if (state.isFirstVisit === null) {
                var res = getCookie('isFirstVisit')
                try {
                    res = JSON.parse(res)
                } catch (error) {
                    res = false
                }
                state.isFirstVisit = res
            }
            return state.isFirstVisit
        },
        getHideNavbar: (state) => {
            return state.hideNavbar
        }
    },

    mutations: {
        /**
         * Set the page title in the state.
         *
         * @param {Object} state - the state object
         * @param {Object} payload - the payload containing the title
         * @return {void}
         */
        setPageTitle(state, payload) {
            state.pageTitle = payload.title
        },
        /**
         * Increases the page depth in the given state.
         *
         * @param {Object} state - the state object to modify
         * @return {void}
         */
        addPageDepth(state) {
            state.pageDepth += 1
        },
        /**
         * Reduces the page depth in the given state.
         *
         * @param {Object} state - the state object
         * @return {void}
         */
        reducePageDepth(state) {
            state.pageDepth -= 1
        },
        /**
         * Clear the page depth in the given state.
         *
         * @param {Object} state - the state object
         * @return {undefined}
         */
        clearPageDepth(state) {
            state.pageDepth = 0
        },
        setDisableBack(state, payload) {
            state.disableBack = payload
        },
        setVisited(state, payload) {
            state.isFirstVisit = payload
            setCookie('isFirstVisit', payload)
        },
        setHideNavbar(state, payload) {
            state.hideNavbar = payload
        }
    }

}

export default page
