<template>
    <div class="TabbarCom" v-if="!store.getters.isFullScreen">
        <van-tabbar
            route
            v-model="active"
            @change="handleTabbarChange"
        >
            <!---------------------------------------------------------------------------------------------------------------------------------------------
             * Home
            ---------------------------------------------------------------------------------------------------------------------------------------------->
            <van-tabbar-item
                replace
                to="/"
                name="home"
                icon="home-o"
            >
                <!-- badge="12" -->
                {{ t("tabbar.home") }}
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { showToast } from 'vant';
import { useStore } from 'vuex';
import i18n from '@/i18n';


// MARK - Props
// ************************************************************************
const { t } = i18n.global
const store = useStore()
const active = ref('home');


// MARK - Methods
// ************************************************************************
/**
 * Handles the tab bar change event.
 *
 * @param {number} index - The index of the tab bar item that was clicked
 * @return {void}
 */
const handleTabbarChange = (index) => {
    // showToast("click ->" + index)
    console.log("tabbar click ->" + index)
    setTimeout(() => {
        store.commit('clearPageDepth')
    }, 200);
}

</script>

<style>
</style>
